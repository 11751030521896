* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contato-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100vh;
  color: white;
  background-color: #04517B;
}

.message-box {
  position: fixed;
  /* Permite que o elemento fique fixo na tela */
  top: 20px;
  /* 20 pixels do topo da tela */
  left: 50%;
  /* Alinha ao meio da tela */
  transform: translateX(-50%);
  /* Move para a esquerda metade da largura do elemento */
  padding: 10px;
  border: 1px solid #4CAF50;
  background-color: #f9f9f9;
  color: #4CAF50;
  border-radius: 5px;
  z-index: 1000;
  /* Certifica que a mensagem fique acima de outros elementos */
}

.contato-container .form-content {
  flex: 1 1 50%;
  margin-right: 50px;
}

form {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
}

h1 {
  margin-bottom: 30px;
  font-size: 2rem;

}

.input-group {
  display: flex;
  justify-content: space-between;
}

.input-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-item label {
  margin-bottom: 5px;
}

.input-item input,
.input-item textarea {
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.input-group .input-item {
  flex: 1;
}

.input-group .input-item:first-child {
  margin-right: 20px;
}

textarea {
  height: 150px;

}

.peixe-logo {
  flex: 1 1 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.peixe-logo img {
  width: 25%;
  justify-content: end;
}

h1 {
  color: white;
}

#peixetext {
  font-size: 3rem;
  max-width: 75%;
}

#botaoSiapreps {
  /* width: 200px; */
  /* height: 80px; */

  /* background-color: transparent;
  margin-left: auto;
  color: #ffffff;
  border-color: #fff;
  border-radius: 50px;
  font-size: 1.3rem;
  padding: 10px 30px; */

  /* Nova config */
  background-color: #283e81;
  border: 1px solid #000;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  height: 40px;
  margin-left: auto;
  width: 100px;

  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  #peixetext {
    font-size: 3rem;
    max-width: 75%;
  }

  .peixe-logo img {
    width: 40%;
    justify-content: end;
  }
}
