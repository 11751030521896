.plataforma {
    /* height: 100vh; */

    article{
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
    }

    div{
        display: none;
        background-color: rgba(39, 116, 43, 1);
        font-size: 1.4rem;
        padding: 2rem 0;
        color: white;

        p{
            width: 80%;
            margin: auto;
            
        }
    }
}

#phytobloom {
    width: 100%;
    height: 100%;
}

/* #verde{
    background-image: url(../../imgs/plataformaWeb.png);
    margin-left: 800px;
    margin-top: -600px;
    color: whitesmoke;
    width: 970px;
    font-size: 40px;
} */
.botaoPhytobloom {
    margin: 0;
    position: absolute;
    bottom: 8rem;
    right: 5rem;

    background-color: rgba(240, 248, 255, 0);
    color: whitesmoke;
    /* margin-left: 1450px; */
    /* margin-top: 20px; */
    font-size: 1.5rem;
    font-weight: bold;
    padding: 20px;
    border-radius: 50px;
    border: 1px solid whitesmoke
}

.botaoPhytobloom a{

    text-decoration: none;
    color: inherit;

    /* Fazendo o A ocupar toda a área do button
        E alinha o texto dentro do A
    */
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

#textoverde {
    display: flex;
    position: absolute;
    bottom: 15rem;
    right: 5rem;
    margin: 0;
    /* margin-top: -700px; */
    /* margin-left: 610px; */


}


@media screen and (max-width: 1366px) {


    #textoverde {
        display: flex;
        margin-top: -400px;
        margin-left: 610px;
        width: 50%;
    }

    /* #phytobloom {
        width: 100%;
    } */
    
    .botaoPhytobloom {
        margin-left: 1075px;
        background-color: rgba(240, 248, 255, 0);
        color: whitesmoke;
        margin-top: 20px;
        height: auto;
        width: 225px;
        border-radius: 50px;
        border: 1px solid whitesmoke
    }

    button.fundoInvinsivel {
        margin: 0;
        height: auto;
        width: 275px;
        border-radius: 50px;
    }
}


@media screen and (max-width: 1024px) {



    #textoverde {
        display: flex;
        margin-top: -400px;
        margin-left: 450px;
        width: 50%;


    }



    #phytobloom {
        width: 100%;

    }

    /* .botaoPhytobloom {
        margin-left: 860px;
        background-color: rgba(240, 248, 255, 0);
        color: whitesmoke;
        margin-top: 20px;
        height: 50px;
        width: 100px;
        border-radius: 15px;
        border: 1px solid whitesmoke
    } */
}

@media screen and (max-width: 1024px) {
    #textoverde, .botaoPhytobloom{
        display: none;
    }
    .plataforma div{
        display: block;
        padding: 20px;
    }

    article{
        display: flex;
        flex-direction: column;
    }
}
