* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.message-box {
  position: fixed;
  /* Permite que o elemento fique fixo na tela */
  top: 20px;
  /* 20 pixels do topo da tela */
  left: 50%;
  /* Alinha ao meio da tela */
  transform: translateX(-50%);
  /* Move para a esquerda metade da largura do elemento */
  padding: 10px;
  border: 1px solid #4CAF50;
  background-color: #f9f9f9;
  color: #4CAF50;
  border-radius: 5px;
  z-index: 1000;
  /* Certifica que a mensagem fique acima de outros elementos */
}


.contact-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: start;
  background-color: #27742B;
  padding: 20px;
  height: 100vh;
  color: white;
  column-gap: 50px;

  padding-top: 20vh;
}

.contact-container .form-content {
  flex: 1 1 50%;
}

#formPhytobloom {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: 50%; */
  /* margin-left: -200px; */

}

#textoform #h1Phytobloom {
  /* margin-bottom: 30px; */
  font-size: 4rem;
  /* margin-right: 500px; */
}

.input-group {
  display: flex;
  justify-content: space-between;
}

.input-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-item label {
  margin-bottom: 5px;
}

.input-item input,
.input-item textarea {
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  min-height: 50px;
}

.input-group .input-item {
  flex: 1;
}



textarea {
  height: 150px;
}


#textoform {
  display: flex;
  justify-content: center;

  flex: 1 1 50%;
  margin-left: 5rem;


  h1 {
    font-size: 4.5rem;
  }

}

h1 {
  color: white;

}

.form-content #botaoPhytobloom {
  width: 100px;
  background-color: #004725a2;
  margin-left: auto;
  color: #ffffff;
  border-radius: 10px;
  /* height: 80px; */
  font-size: 1rem;
  height: 40px;
  
  /* padding: 10px 15px; */
  
  border: 1px solid black;
  /* border-color: #fff; */

  cursor: pointer;
}

@media screen and (max-width: 1366px) {

  .contact-container {
    align-items: start;
    padding: 100px 20px 20px 20px;
    height: 90vh;
  }

  #textoform h1 {
    font-size: 3rem;
  }

  .form-content,
  #formPhytobloom {
    margin: 0;
  }

  .phytobloomContact #botaoPhytobloom{
    width: auto;
    background-color: #004725;
    /* margin-left: 89%; */
    color: #ffffff;
    border: none;
    border-radius: 10px;
    height: auto;
    padding: 10px 20px;
    font-size: 1.1rem;
  }

}


@media screen and (max-width: 1024px) {

  #botaoPhytobloom {
    width: 100px;
    background-color: #00472500;
    align-items: center;
    color: #ffffff;
    border-radius: 10px;
    height: 60px;
  }

  .contact-container {
    height: auto;
    max-height: 100vh;

  }

}

@media screen and (max-width: 1024px) {

  .contact-container .form-content {
    margin: 0;

    #formPhytobloom {
      margin: 0;
    }
  }

  .contact-container {
    background-color: #27742B;
    padding: 2rem 0;
    flex-flow: column nowrap;
    align-items: center;
    min-height: auto;

    #textoform {
      margin: 0;

      #h1Phytobloom {
        font-size: 2rem;
        width: 75%;
        text-align: center;
        margin: 0;
        color: black;
      }
    }

    .form-content form {
      margin: auto;

      input,
      textArea {
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }

    h1,
    label {
      color: #fff;
    }
  }
}

@media screen and (max-width: 500px) {
  .contact-container {
    margin: 0 5px;
  }
}