.containerPesca{

  background-image: url(/src/imgs/marBarco.png);
  background-repeat: no-repeat;
  background-size: 100% 1100px;

  height: 65vh;
  width: 100%;
  position: relative;


  padding-top: 25px;
}

/* IMPROVISO PARA REMOVER A MARCA D'AGUA DO BARCO */
@media screen and (max-height: 850px) {
    .containerPesca{
      height: 80vh;
    }
}

@media screen and (max-height: 650px) {
    .containerPesca{
      height: 100vh;
    }
}

/* IMPROVISO(ACIMA) */

.Pesca {
  position: relative;
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
}

/* .barcoPesca {
    width: 100%;
    height: auto;
    margin-top: -120px;
  } */

.divBarco{
  height: 700px;

  user-select: none;
}

/* #tresd {
  margin-top: -1100px;
} */

.textoPesca {
  /* position: absolute; */
  /* margin-top: -1100px; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4rem;
  font-weight: bold;
  color: #fff;

}

/* .porcentagem {
  display: flex;
} */

#borda {
  /* margin-top: -500px; */
  /* margin-left: 150px; */

  width: 90%;


  position: absolute;
  bottom: 20px;
  left: 50px;
}

#textoBarco {
  /* margin-top: -500px; */
  /* margin-left: -1550px; */
  width: 90%;

  position: absolute;
  bottom: 50px;
  left: 70px;
}

#video {
  margin-top: 1000px;
}

/* .textoporcentagem {
    text-align: center;
    max-width: 200px;
    margin-top: -250px;
  }
  
  .textoporcentagem h2 {
    font-size: 2.5em;
    margin: 0;
    color: #fff;
  }
  
  .textoporcentagem p {
    font-size: 1em;
    color: #fff;
  }
   */
@media screen and (max-width: 1366px) {

  /* #borda {
    margin-top: -300px;
    width: 1000px;
    margin-left: 200px;
  }

  #textoBarco {
    width: 1000px;
    margin-top: -300px;
    margin-left: -1000px;

  } */

  .barcoPesca {
    width: 100%;
    height: auto;
  }

  /* .textoPesca {
    position: absolute;
    margin-top: -700px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 4em;
    color: #fff;

  } */

  .porcentagem {
    display: flex;

  }



}


@media screen and (max-width: 1024px) {


    #borda{
      margin-top: -700px;
      width: 800px;
      margin-left: 100px;
    }
    #textoBarco{
      width: 800px;
      margin-top: -700px;
      margin-left: -800px;
      
    }

    /* #tresd{
      margin-top: -350px;
    } */
    
    .textoPesca {
      /* position: absolute;
      margin-top: -980px; */
      width: 100%;
      /* height: 100%; */
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 3em;
      color: #fff;
  
    }
    
    .porcentagem{
      display: flex;
      
    }

  }

  .barcoPesca {
    width: 100%;
    height: auto;
  }

  .textoPesca {
    /* position: absolute; */
    /* margin-top: -500px; */
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3em;
    color: #fff;

  } 

  /* .porcentagem {
    display: flex;
  } */

  /* modificaçoes para objeto 3d em 1024px ficar responsivo*/