.planta {

  /* padding-top: 100px; */
  .monitoring-section {
    display: flex;
    align-items: center;
    width: 75%;
    margin: auto;
    /* margin-left: 5rem; */
    padding: 1rem 0;

    .planta-imagem {
      /* width: 100%; */
      /* margin-right: 20px;
      margin-left: -200px; */
      /* flex: 1 1 20%; */

      width: 500px;
    }

    .content {

      flex: 1 1 80%;

      h2 {
        color: #2e7d32;
        /* font-size: 50px; */
        font-size: 42px;

        margin-bottom: 20px;
        /* max-width: 75%; */

      }

      img {
        width: 54px;
        height: 54px;
      }

      ul {
        list-style: none;

        li {
          display: flex;
          align-items: center;

          /* margin-bottom: 100px; */
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {

  .planta .monitoring-section {
    margin: 0;
    margin-left: 4rem;
  }

  .planta {
    padding-top: 25px;

    .monitoring-section {
      display: flex;
      align-items: center;
      /* padding: 10%; */

      .planta-imagem {
        width: 50%;
        /* margin-right: 20px; */
        /* margin-left: -200px; */
        /* flex: 1 1 20%; */

        margin: 0;
      }

      .content {
        /* flex: 1 1 25%; */

        h2 {
          color: #2e7d32;
          margin-bottom: 20px;

          /* font-size: 50px; */

          font-size: 32px;
        }

        img {
          width: 54px;
          height: 54px;
        }

        ul {
          list-style: none;



          li {
            display: flex;
            align-items: center;
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  .planta {
    padding-top: -100px;

    .monitoring-section {
      display: flex;
      align-items: center;


      .planta-imagem {
        width: 150px;
        /* margin-top: -400px; */
        align-items: center;
      }

      .content {
        /* margin-top: -250px; */

        h2 {
          color: #2e7d32;
          margin-bottom: 2px;
          font-size: 50px;


        }

        img {
          width: 54px;
          height: 54px;
        }

        ul {
          list-style: none;



          li {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .planta .monitoring-section {
    flex-flow: column nowrap;

    .content h2 {
      font-size: 2rem;

      /* Ajuste no texto */
      text-align: center;
    }

    ul li {
      flex-flow: column nowrap;
      justify-content: center;
      row-gap: 1rem;
      text-align: center;
    }
  }
}

@media screen and (max-width:500px) {
  .planta .monitoring-section {
    margin: 0 auto;
    flex-flow: column nowrap;

    .content h2 {
      font-size: 1.8rem;
    }

    ul li {
      flex-flow: column nowrap;
      justify-content: center;
      row-gap: 1rem;
      text-align: center;
    }
  }
}