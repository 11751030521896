.carrossel-container {
  text-align: center;
  margin: 20px auto;
  width: 80%;
  max-width: 800px;
  /* margin-top: 200px; */
}

.carrossel {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.membro {
  min-width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

.membro img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.arrow {
  background: none;
  border: none;
  font-size: 5em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arrow.left {
  left: 0;

}

.arrow.right {
  right: 0;
}

.cardumeh2 {
  color: #233363;
  font-size: 2.3rem;
  text-align: center;
 
}

.cardumeh3 {
  font-weight: 400;
}

.cardumep {
  color: #233363;
  font-size: 2.5em;
  font-style: italic;

}

@media screen and (max-width: 1366px) {

  .carrossel-container {
    text-align: center;
    margin: 20px auto;
    width: 80%;
    max-width: 800px;
    margin-top: 50px;
  }
  
  .carrossel {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }
  
  .membro {
    min-width: 100%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
  }
  
  .membro img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  
  .arrow {
    background: none;
    border: none;
    font-size: 5em;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .arrow.left {
    left: 0;
    
  }
  
  .arrow.right {
    right: 0;
  }
  
  .cardumeh2{
    color:#233363;
    font-size: 2.5em;
    text-align: center;
  

  }


}