.footerSiapreps {
  background-color: #233363;
  color: #fff;
  padding: 10px;

  box-sizing: border-box;

  .footer-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    >div{
      flex: 1 1 33%;
    }
  }

  .social-media {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    column-gap: 5px;
  }

  a {
    font-size: 0.8rem;
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    row-gap: 10px;

    img{
      width: 20px;
    }
  }

  p {
    color: #fff;
    padding: 0 !important;
    text-align: center;
  }

  /* #copy{
    margin-left: 210px;
   } */
   

}

.footer-bottom{
  display: flex;
  justify-content: center;
}

.imgfooter {
  width: 50px;
}

#logo {
  width: 75px;
  align-items: center;
}

@media (max-width: 1150px){

  .footerSiapreps .social-media {
    display: none;
    background-color: black;
  }

}

@media screen and (max-width: 500px)  {
  #copy{
    font-size: 0.6rem;
  }
}