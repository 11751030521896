@import 'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap';

.header {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}

/* Trocando a logo com base no container da página */
.header .logoAzul, .header .logoResponsiva{
  display: none;
}

.index .header .logoBranca, .index .header .logoResponsiva{
  display: none;
}

.index .header .logoAzul{
  display: block;
}

.header img {
  width: 14rem;
}

.header_container .article_header_container button{
  display: none;
}

.header nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.header nav ul a {
  /* color: rgb(35, 51, 99); */
  color: #FFFFFF;
  text-decoration: none;
  font-size: 1.5rem;
}

.index nav ul a{
  color: rgba(35, 51, 99, 1);
}

.header_container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 10%;
}

.header_container img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.header_container .bg_img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 1600px) {
  .header_container .bg_img {
    height: 90%;
  }
}

/* .header_container .article_header_container {
  padding-top: 1rem;
  width: 100%;
  margin: auto 75rem auto auto;
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-rows: 50% 50%;
  color: #FFFFFF;
  gap: 2rem 2rem;
  margin-top: 40px; 
} */

.header_container .article_header_container {
  grid-gap: 2rem 2rem;
  color: #fff;
  display: grid;
  gap: 2rem 2rem;
  grid-template-columns: 45% 55%;
  grid-template-rows: 50% 50%;
  margin: 40px 75rem auto auto;
  padding-top: 1rem;
  width: 100%;
}


.header_container .article_header_container img {
  width: 28rem;
  grid-row: 1/3;
  grid-column: 1/2;
  margin-left: 2rem;
  align-self: end;
  margin-top: 5%;
}

.header_container .article_header_container #h2principal {
  grid-column: 2/3;
  grid-row: 1/2;
  width: 80%;
  font-size: 2.5rem;
  align-self: end;
}

.header_container .article_header_container #pprincipal {
  grid-column: 2/8;
  grid-row: 2/3;
  width: 55rem;
  font-size: 2rem;
}

.header_container .article_header_container button{
  background-color: rgba(3, 4, 94, 1);
  height: 50px;
  padding: 15px;
  border-radius: 50px;
  color: white;
}

.header_container .article_header_container div button a{
  text-decoration: none;
  color: inherit;

  /* Fazendo o A ocupar toda a área do button
      E alinha o texto dentro do A
  */
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.header_container .footer_header_container {
  position: relative;
  bottom: 4rem;
  z-index: -1;
}

@media only screen and (max-width: 1920px) {
  .header_container .footer_header_container {
    bottom: 3rem;
  }
}

@media only screen and (max-width: 1600px) {
  .header_container .footer_header_container {
    bottom: 6rem;
  }
}

.header_container .footer_header_container img {
  width: 100%;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.header_container .footer_header_container article {
  position: absolute;
  bottom: 3.8rem;
  color: #FFFFFF;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20rem;
  align-items: center;
  z-index: 2;
}

@media only screen and (max-width: 1920px) {
  .header_container .footer_header_container article {
    bottom: 2rem;
  }
}

@media only screen and (max-width: 1920px) {
  .header_container .footer_header_container article {
    gap: 15rem;
  }
}

@media only screen and (max-width: 1600px) {
  .header_container .footer_header_container article {
    gap: 8rem;
    bottom: 1rem;
  }
}

.header_container .footer_header_container article .tracejado {
  width: 2px;
  border: thin solid #FFFFFF;
  background-color: #FFFFFF;
  height: 5rem;
}

.header_container .footer_header_container article div .title {
  font-size: 2.5rem;
}

@media only screen and (max-width: 1600px) {
  .header_container .footer_header_container article div .title {
    font-size: 1.4rem;
  }
}

.header_container .footer_header_container article div .content {
  font-size: 1rem;
}

.helps {
  padding-top: 2rem;
  padding-bottom: 50px;

  display: flex;
  flex-direction: column;
  row-gap: 3rem;

}

.helps .helps_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 6rem;
  padding: 0 30px;
}

.helps .helps_container .helps_article {
  color: #233363;
}

.helps .helps_container .helps_article .title {
  font-size: 3rem;
  width: 26rem;
  margin-top: 2rem;
  position: relative;
}

.helps .helps_container .helps_article .title::first-letter{
  margin-left: 10px;
}

.helps .helps_container .helps_article .title::before{
  content: "";
  background-image: url("../imgs/setasTitulo.png");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;

  position:absolute;
  left: -25px;
  top: -15px;

  width: 45px;
  height: 50px;
}

.helps .helps_container .helps_article .content {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.helps .helps_container .cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
  align-items: center;

  width: auto;
}

.helps .helps_container .cards .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1 1 30%;

  height: 100%;
  width: 15rem;
  gap: 2rem;
  padding: 0.8rem;
}

.helps .helps_container .cards .card div{
  flex-basis: 100px;
}

.helps .helps_container .cards .card img {
  width: 6rem;
}

.helps .helps_container .cards .card .title {
  color: #233363;
}

.helps .helps_container .cards .card p {
  color: #233363;
}

.helps h3 {
  text-align: center;
  font-size: 4rem;
  color: #233363;
}

.headersobre {
  background-color: #04517B;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 35rem;
}

.sistema-img {
  max-width: 300px;
  margin-right: 15rem;
  padding-top: 30px;
}

.imagemsiapesq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagemsiapesq p {
  color: white;
  max-width: 600px;
}

.imagem-equipe {
  width: 60rem;
  display: block;
  align-items: right;
  margin: 2rem auto;
}

.equipe h1 {
  color: #04517B;
  text-align: center;
}

.cards img {
  border-radius: 20px;
}

.cards p {
  color: #04517B;
}

.card1 {
  width: 60rem;
  display: flex;
  justify-content: flex-end;
  margin: 10rem;
}

.card1 p {
  max-width: 600px;
  margin-left: auto;
  margin: auto;
  padding-top: 30px;
  margin-right: 5rem;
}

.card2 {
  width: 60rem;
  display: flex;
  justify-content: flex-end;
  margin: 10rem;
}

.card2 p {
  max-width: 600px;
  margin-left: auto;
  margin: auto;
  padding-top: 30px;
  margin-right: 5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

/*# sourceMappingURL=style.css.map */

.header nav {
  width: 100%;
}

.header .nav ul {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.header .responsiveButton button {
  display: none;
  background-color: rgba(3, 4, 94, 1);
  width: 25%;
  height: 50px;
  padding: 15px;
  border-radius: 50px;
  color: white;
}

/* .responsiveHeader button {
  background-color: rgba(3, 4, 94, 1);
  width: calc(100% + 10px);
  height: 50px;
  padding: 15px;
  border-radius: 50px;
  color: white;
} */

.index section {
  
  width: 100%;
  position: relative;
}

.header_container .footer_header_container {
  position: absolute;
  bottom: 0px;
}

@media screen and (max-width:1600px) {
  .header_container .article_header_container #h2principal{
    font-size: 2.3rem;
  }

  .header_container .article_header_container #pprincipal {
    font-size: 1.4rem;
    width: 37rem;
    grid-row: auto;
    grid-column: auto;
  }
}

@media screen and (max-width: 1366px) {
  .header_container {
    padding-bottom: 10%;
  }

  .header_container .article_header_container {
    padding-top: 2rem;
    margin-top: 0;
    gap: 2rem 3rem;
  }

  .header_container .article_header_container #h2principal{
    font-size: 1.5rem;
  }

  .header_container .article_header_container #pprincipal{
    width: 80%;
    font-size: 1.4rem;
  }
  

  .header_container .footer_header_container {
    position: absolute;
    bottom: -5px;
  }
}

.principal{
  width: 100%;
}

@media screen and (max-width: 1024px) {

  .header .logoBranca, .logoAzul{
    display: none;
  }

  .header .logoResponsiva{
    display: block;
  }

  .header_container .footer_header_container {
    display: none;
  }

  .header_container .bg_img{
    height: 100%;
  }


  .header_container .article_header_container {
    width: 100%;
    padding-left: 5%;
    margin: 4rem 0 0 0;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    row-gap: 20px;

    img {
      width: 75%;

      margin: 0;

      /* align-self: flex-start; */

      grid-column: auto;
      grid-row: auto;
    }

    div{
      display: flex;
      flex-flow: column nowrap;
      justify-content: start;
      align-items: start;
      row-gap: 10px;
    }

    #h2principal{
      width: 80%;
      font-size: 1.2rem;
      /* align-self: flex-start; */

      grid-area: title;
    }

    p#pprincipal {
      width: 80%;
      overflow: hidden;

      /* margin: 1rem 0; */

      font-size: 1.2rem;

      grid-area: text;
    }

    .header_container .article_header_container img{
      width: 30%;
    }

  }

  .helps .helps_container .helps_article{
    flex-flow: column;
  }

  .header_container {
    content: "";
    height: 100%;
    background-color: rgba(0, 0, 0, 0.295);
  }

  
  /*MENU*/
  .links {
    display: none;
  }

  .header .responsiveButton button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(3, 4, 94, 1);
    width: 100%;
    height: 50px;
    /* padding: 10px; */
    border-radius: 50px;
    color: white;
  }

  .header nav ul {
    justify-content: space-evenly;
  }

  .helps h3{
    display: none;
  }
}

@media screen and (max-width: 1024px) {

  .helps .helps_container .helps_article .title {
    font-size: 2rem;
  }

  .header_container .article_header_container{

    height: 75%;
    width: 100%;

    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: repeat(3, 100px);
    grid-template-areas: 
    "title image"
    "text image"
    "button image";

    gap: 1rem 2rem;

    /* display: flex;
    flex-flow: column nowrap;
    align-items: start; */
  }

  .header_container .article_header_container button{
    display: block;
    width: 50%;

    grid-area: button;
  }

  .header_container .article_header_container img{
    order: 3;

    grid-area: image;

    margin: auto;
  }

  .helps .helps_container .helps_article .title::before{
    width: 20px;
    left: 2px;
    top: 0px;

  }

  .header img {
    width: 10rem;
  }

  .helps .helps_container .helps_article .title{
    width: 100%;
  }

  .index .header .logoAzul{
    display: none;
  }

  .index .header .logoResponsiva{
    display: block;
  }
}

button{
  border: 1px solid black;
}

@media screen and (max-width: 650px) {
  .header_container .article_header_container{
    display: flex;
    flex-flow: column nowrap;
    align-items: start;
  }

  .header_container .article_header_container #h2principal{
    align-self: start;
  }

  .header_container .article_header_container img{
    margin: 0;
    align-self: start;

    width: 200px;
  }

  .header_container .article_header_container p#pprincipal{
    width: 75%;
  }
}

@media screen and (max-width:500px){
  .header nav img{
    width: 6.5rem;
  }

  .header .responsiveButton button{
    font-size: 0.6rem;
  }

  .header_container .bg_img{
    height: 100%;
  }

  .header_container .article_header_container #h2principal{
    font-size: 1.2rem;
    width: 90%;
  }

  .header_container .article_header_container p#pprincipal{
    width: 90%;
    font-size: 1.2rem;

    overflow: hidden;
    margin: 1rem 0;
  }
}