/* .footer {
  background-color: #27742B;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .social-media {
    display: flex;
    gap: 50px;
  }

  a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;

  }

  p {
    color: #fff;
  }


}


.footer-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
}

.imgfooter {
  width: 50px;
}

#logo {
  width: 150px;
  align-items: center;
  margin-left: 300px;
} */


.footer {
  background-color: #004725;
  color: #fff;
  padding: 20px 20px;

  box-sizing: border-box;

  .footer-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    >div{
      flex: 1 1 33%;
    }
  }

  .social-media {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    column-gap: 5px;
  }

  a {
    font-size: 0.8rem;
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    row-gap: 10px;

    img{
      width: 20px;
    }
  }

  p {
    color: #fff;
    padding: 0 !important;
    text-align: center;
  }

  /* #copyphytoblom{
    margin-left: 500px;
   } */
   

}

.footer-bottom{
  display: flex;
  justify-content: center;
}

.imgfooter {
  width: 50px;
  
}

#logo {
  width: 75px;
  align-items: center;
  /* margin-left: 400px; */
}

@media (max-width: 1150px){
  .footer-content .social-media {
    display: none;
    background-color: black;
  }
}

@media screen and (max-width: 500px) {
  #copyphytoblom{
    font-size: 0.6rem;
  }
}