.siapreps {
  display: flex;
  flex-direction: column;
  background-image: url("../../../imgs/section_siapreps_bg.png");
  /* margin-top: 100px; */
  background-position-x: center;
  width: 100%;
  height: 1000px;
  align-items: center;
}

.siapreps {
  display: flex;
  flex-direction: column;
}

.siapreps_texto{
  p{
    font-size: 2.4rem;
    width: 90%;
    margin: auto;
    text-align: center;
  }
}

.siapreps_container {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  /* Distribui os elementos com espaço entre eles */
  width: 100%;
  /* Garante que a div ocupe 100% da largura da tela */
}

.siapreps_logo {
  background-color: white;
  border-radius: 120px;
  width: 696px;
  height: 390px;
  padding: 50px;
  margin-top: 100px;
  margin-left: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.list-column {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  margin-right: 50px;
  color: white;
  font-weight: bold;
}

.list-column ul li {
  list-style-type: none;
  font-size: 32px;
  font-weight: bold;
  margin-top: 70px;
}

.iconChecked {
  position: absolute;
  margin-left: -80px;
}

.tubarao {
  margin-top: 120px;
  margin-right: 170px;

}

#siapreps-enviar .tubaraoB{

  margin-right: auto;

  img{
    width: 300px;
    height: 100px;
  }
}

.siapreps_bottom {
  margin-top: 100px;
  color: white;
  font-size: 28px;
  justify-content: center;
  font-weight: bold;

  max-width: 100%;
}

/* .btn_more{
  width: '370px';
  height: '120px'; 
  border-radius: '100px';
  justify-content: "center";
} */

.btn-more {
  background-color: transparent;
  /* Transparente no centro */
  border: 2px solid #000;
  /* Borda definida */

  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

#siapreps-enviar {
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: 5%; */
}

#siapreps-enviar div{
  flex:1 1 30%;

  display: flex;
  align-items: center;
  justify-content: center;
}

#siapreps-enviar .peixes{
  margin-left: auto;
}

#siapreps-enviar .peixes img{
  width: 300px;
}

#button {
  /* background-color: transparent;
  width: 100px;
  height: 40px;
  border: 2px solid #000;
  color: #fff;
  border-radius: 10px; */

  background-color: rgba(240, 248, 255, 0);
  color: whitesmoke;
  /* height: 100px; */
  /* width: 250px; */
  padding: 20px;
  text-decoration: none;
  border-radius: 50px;
  border: 2px solid whitesmoke;

  /* margin-left: 100%;
  margin-right: 100%;
  margin-top: 150px; */

  font-size: 1em;
}

@media screen and (max-width: 1919px) {

  .tubarao {
    margin: 0;
    position: absolute;
    top: 30px;
    right: 30px;

    img{
      width: 100px;
    }
  }

  /* .siapreps_texto{
    h2{
      font-size: 2.4rem;
    }
  } */

  .siapreps_logo{
    flex: 1 1 50%;
    background: none;
    padding: 0;
    
    margin: 0;
    justify-content: center;
    align-items: center;
    /* padding-right: 3rem; */
    
    img{
      padding: 30px;
      width: 75%;
      background-color: #fff;
      border-radius: 60px;
    
      width: 500px;
      height: auto;
    }
  }

  .list-column ul li {
    list-style-type: none;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 40px;
    
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: center;
    column-gap: 20px;
  }
  
  .list-column{
    padding-left: 3rem;
    justify-content: start;
    flex: 1 1 50%;
    /* width: 100%; */
    /* height: 100%; */
    margin: 0;
  }
  
  .iconChecked{
    position: static;
    margin: 0;
    width: 40px;
  }

  #siapreps-enviar .tubaraoB{
    margin-left: 40px;
  }
  
  #siapreps-enviar .tubaraoB{
    margin: 0;
    width: 200px;
    /* transform: rotate(30deg); */
    /* bottom: 40px;
    left: 60px; */
    margin-right: auto;
  }
  
  .peixes{
    margin-left: auto;
  }

}


@media screen and (max-width: 1366px) {

  #siapreps-enviar .tubaraoB img{
    bottom: 75px;
    left: 75px;

    width: 225px;
    height: 80px;
  }

  #siapreps-enviar .peixes img{
    right: 20px;
    bottom: 20px;

    width: 200px;
  }

  .siapreps{
    position: relative;
    flex: 1 1 50%;
    height: calc(100vh + 10vh);

    .siapreps_texto{
      font-size: 1.2rem;
    }
  }

  .tubarao {
    margin: 0;
    position: absolute;
    top: 30px;
    right: 30px;

    img{
      width: 100px;
    }
  }

  
  .siapreps_logo {
    flex: 1 1 50%;
    background: none;
    padding: 0;
    
    margin: 0;
    justify-content: end;
    align-items: center;
    padding-right: 3rem;
    
    div{
      padding: 30px;
      width: 75%;
      background-color: #fff;
      border-radius: 60px;
    }
    
    img{
      width: 100%;
      height: auto;
      border-radius: 0;
      background: none;
    }
  }
  
  .list-column ul li {
    list-style-type: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 40px;
    
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: center;
    column-gap: 20px;
  }
  
  .list-column{
    padding-left: 3rem;
    justify-content: start;
    flex: 1 1 50%;
    /* width: 100%; */
    /* height: 100%; */
    margin: 0;
  }
  
  .iconChecked{
    position: static;
    margin: 0;
    width: 40px;
  }
  
  .siapreps_container {
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    flex:1 1 50%;
  }
  
  .siapreps_texto{
    p{
      font-size: 1.7rem;
    }
    margin-bottom: 3rem;
  }
  
  .siapreps_bottom .btn_more{
    width: 100%;
  }

  .siapreps_bottom{
    flex: 1 1 50%;
    margin: 0;
    
    #siapreps-enviar{
      width: 100%;
    }
    
    img{
      position: absolute;
    }
    
    #button{
      margin: 0 auto;
      width: 200px;
      height: 75px;
      border-radius: 50px;
      font-weight: bold;
    }

    .tubaraoB{
      width: 200px;
      /* transform: rotate(30deg); */
      bottom: 40px;
      left: 60px;
    }
    
    .peixes{
      bottom: 10px;
      right: 20px;

      width: 200px;
    }
  }
}

@media screen and (max-width: 1024px) {

  .helps .helps_container .cards .card{
    height: auto;
  }

  .siapreps{
    background-image: none;
    background-color: rgba(45, 129, 175, 1);

    padding: 2rem 0;
    height: auto;
    row-gap: 3rem;
  }

  .siapreps_logo{
    justify-content: center;
    width: auto;
    height: auto;
    padding: 0;

    div{
      width: 225px;
      border-radius: 75px;
      display: flex;
      justify-content: center;

      padding: 10px 0;

      img{
        width: 200px;
      }
    }
  }

  .siapreps_texto{
    p{
      font-size: 1.3rem;
      width: 75%;
      margin: auto;
    }
  }

  .list-column{
    padding: 0;
  }

  .list-column ul li{
    padding: 0;
    font-size: 1rem;
  }

  /* .siapreps_container {
    flex-flow: column nowrap;
    row-gap: 1rem;
  } */
  
   .tubarao img, .tubaraoB img, .peixes img{
    display: none;
  }


  #siapreps-enviar{
    justify-content: start;
    #button{
      margin: auto;
    }
  }
}

@media screen and (max-width: 600px) {
  .siapreps_container{
    flex-flow: column nowrap;
  }

  .siapreps_texto p{
    text-align: left;
    width: 75%;
    font-size: 1rem;
  }
}