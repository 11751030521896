.container {
  text-align: center;
  background-image: url('/src/imgs/hidro.png');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: white;
  /* margin-top: 60%; */


  header,
  main {
    flex: 1 1 50%;
    font-size: 1.6rem;
  }
}

/* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

.header {

  .logo,
  .header-text {
    flex: 1 1 40%;
  }

  .header-text {
    flex: 2 2 60%;
  }
}

.header-text {
  display: flex;
  justify-content: end;
  align-items: end;

  li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 30px;

    .iconChecked {
      width: 4rem;
      margin: 0;
      position: static;
    }
  }
}

/* .header h1 {
    font-size: 1.5em;
    margin: 10px 0;
    display: flex;
    align-items: center;
  } */

/* .header h1:before {
    content: '✔️';
    margin-right: 10px;
    color: #00c853;
  } */

.logo {
  display: flex;
  align-items: end;


  div {
    margin-left: 50px;
    width: 60%;
    padding: 20px;
    background-color: white;
    border-radius: 80px;
  }

  img {
    width: 75%;
  }
}

main {
  margin-top: 50px;
}

main p {
  font-size: 1.2em;
  max-width: 75%;
  font-weight: 700;
  margin: 0 auto;
}

.saiba-mais {
  display: inline-block;
  text-decoration: none;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 50px;

  padding: 15px 40px;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 50px;
}


@media screen and (max-width: 1366px) {
  .container {
    /* display: flex; */
    /* flex-flow: column nowrap; */
    justify-content: center;
    flex: 1 1 50%;
    font-weight: bold;

    .header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    .header-text {

      flex: 1 1 50%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: end;

      ul {
        list-style-type: none;
        margin-top: 20px;

        li {
          display: flex;
          flex-flow: row nowrap;
          justify-content: start;
          align-items: center;
          column-gap: 5px;
          font-size: 1.3rem;

          margin-bottom: 20px;
        }
      }

      .iconChecked {
        width: 35px;
      }
    }

    .logo {
      flex: 1 1 50%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      height: 100%;
    }

    header,
    main {
      font-size: 1rem;
    }
  }

  .logo div {
    margin: 0;
    width: 50%;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo img {
    background: none;
    width: 75%;
  }

  .container main {
    p {
      max-width: 75%;
      font-size: 1.5rem;
    }
  }

  .saiba-mais {
    text-decoration: none;
    color: whitesmoke;
    text-align: center;

    background-color: rgba(240, 248, 255, 0);
    /* height: 50px; */
    border-radius: 40px;
    border: 2px solid whitesmoke;
    padding: 15px 40px;


    /* font-size: 1.5rem; */
  }
}

@media screen and (max-width: 1024px) {
  .container main {
    p {
      max-width: 90%;
      font-size: 1rem;
      text-align: left;
    }
  }

  main{
    margin: 0;
  }

  .container {

    row-gap: 2rem;
    min-height: auto;
    background-image: none;
    background-color: rgba(16, 70, 23, 0.77);

    .header {
      /* flex-flow: column-reverse nowrap; */
      flex-basis: auto;

      row-gap: 2rem;
    }

    .header-text ul li{
      font-size: 1rem;
      text-align: left;
    }
  }

  .logo div {
    width: 75%;
    border-radius: 75px;
  }

  .logo img {
    background: none;
    width: 50%;
  }

}

@media screen and (max-width: 600px) {
  .container .header{
    flex-flow: column-reverse nowrap;
  }

  .container .header-text ul li{
    text-align: left;
  }
}