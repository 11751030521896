@import "./modules/header.scss";
@import "./modules/index.scss";
@import "./modules/about.scss";

@import 'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap';

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Mulish", sans-serif;
}