.plataforma{
    width: 100%;
}

#plataforma {
    width: 100%;
    height: 100%;
    /* margin-top: 4.5%;  */
}

.fundoInvinsivel2 {
    /* margin-left: -125px; */
    /* margin-top: 650px; */

    background-color: rgba(240, 248, 255, 0);
    color: whitesmoke;
    height: 50px;
    width: 125px;

    padding: 10px;
    border-radius: 20px;
    border: 1px solid whitesmoke;

    position: absolute;
    bottom: 10%;
    right: 7%;
}

.fundoInvinsivel2 a{

    text-decoration: none;
    color: inherit;

    /* Fazendo o A ocupar toda a área do button
        E alinha o texto dentro do A
    */
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

#textoazul {
    display: flex;
    margin-top: 75px;
    margin-left: -1200px;
}

@media screen and (max-width: 1366px) {

    #textoazul {
       position: absolute;
       top: 20px;
       right: 20px;
        width: 800px;
    }

    .plataforma article .fundoInvinsivel2 {
        /* margin-left: 1100px; */
        background-color: rgba(240, 248, 255, 0);
        color: whitesmoke;

        /* margin-top: 50px; */

        height: 50px;
        width: 125px;
        border-radius: 15px;
        border: 1px solid whitesmoke;

        position: absolute;
        top: 400px;
    }

}


@media screen and (max-width: 1024px) {

    /* #textoazul {
        display: flex;
        margin-top: -400px;
        margin-left: 400px;
        width: 550px;
    }

    .fundoInvinsivel {
        margin-left: 850px;
        background-color: rgba(240, 248, 255, 0);
        color: whitesmoke;
        margin-top: 50px;
        height: 50px;
        width: 100px;
        border-radius: 15px;
        border: 1px solid whitesmoke
    } */

}