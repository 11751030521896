

.parceiros {
  display: flex;
  justify-content: center;

  img{
    width: 80%;
    height: 700px;
  }
}


@media screen and (max-width: 1024px) {
  .parceiros img{
    width: 90%;
    height: 100%;
    margin: 30px 0;
  }
}

