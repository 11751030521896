* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.formContato{
  background: #04517B;
}

.form-content .formContato{
  width: 85%;
}

.formContato #botao{
  width: 100px;
  height: 40px;

  border-radius: 10px;

  background: #03045E;
  color: white;

  margin-left: auto;
}

.form-infos, .form-content{
  flex: 0 1 40%;
}

.form-infos h1{
  font-size: 4rem;
}

.form-infos p{
  font-size: 1.2rem;
  font-weight: 700;
}

.contactContainer{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  /* align-items: center; */
  column-gap: 20px;
  height: 90vh;

  padding-top: 100px;
  padding-top: 10vh;

  color: white;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #27742B;
  padding: 20px;
  height: 100vh;
  color: white;
  column-gap: 50px;
}

/* .contact-container .form-content {
  flex: 1 1 50%;
} */

#formPhytobloom {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: 50%; */
  /* margin-left: -200px; */
}

h1{
  color: white;
  
}

#botaoPhytobloom{
  width: auto;
  font-size: 1.5rem;
  height: auto;
  padding: 5px 20px;

  background-color: #00472500;
  color: #ffffff;

  border: 1px solid white;
  border-radius: 50px;

  margin-left: auto;
}

@media screen and (max-width: 1366px) {

  .contact-container{
    align-items: start;
    padding: 100px 20px 20px 20px;
    height: 90vh;
  }

  #textoform h1{
    font-size: 3rem;
  }

  .form-content, #formPhytobloom{
    margin: 0;
  }

  #botaoPhytobloom{
    width: auto;
    background-color: #004725;
    /* margin-left: 89%; */
    color: #ffffff;
    border-radius: 10px;
    height: auto;
    padding: 10px;
    font-size: 1.5rem;
  }

}


@media screen and (max-width: 1024px) {
 
#botaoPhytobloom{
  /* width: 48px; */
  width: auto;
  background-color: #004725;
  margin-left: 83%;
  color: #ffffff;
  border-radius: 10px;
  /* height: 30px; */
  height: auto;
  padding: 10px;
  font-size: 1rem;
}

.contact-container{
  height: auto;
  max-height: 100vh;
}

}

@media screen and (max-width: 1024px) {

.contact-container .form-content{
  margin: 0;

  #formPhytobloom{
    margin: 0;
  }
}

.contact-container{
  background-color: #fff;
  padding: 2rem 0;
  flex-flow: column nowrap;
  align-items: center;
  min-height: auto;

  
#textoform{
    display: flex;
    justify-content: center;
   
  }
  
  h1{
    color: white;

    #h1Phytobloom{
      font-size: 2rem;
      width: 75%;
      text-align: center;
      margin: 0;
    }
  }
  
  #botao{
    width: 200px;
    height: 80px;
    background-color: #03055e00;
    margin-left:510px;
    color: #ffffff;
    border-radius: 40px;
    border-color: #ffffff;
    font-size: 3em;
   
  }

  @media screen and (max-width: 1024px) {

    .contactContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #04517B;
      padding: 20px;
      height: 150vh;
      color: white;
      margin-top: -20%;
    }

    #botao{
      width: 120px;
      height: 50px;
      background-color: #03055e00;
      margin-left:150px;
      color: #ffffff;
      border-radius: 40px;
      border-color: #ffffff;
      font-size: 2em;
     
    }

  }
}
}


@media screen and (max-width: 500px){
  #botaoPhytobloom{
    margin-left: auto;
  }
}