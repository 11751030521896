.cards {
  text-align: center;
  /* padding: 200px; */
}

#h1cardume {
  font-size: 35px;
  color: #1e3066;
  margin-bottom: 10px;
  
}

#peixetextinho {
  color: #233363;
  margin-bottom: 20px;
  font-size: 25px;
}


.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.card {
  width: 22%;
  margin-bottom: 20px;
}

.textopeixe {
  font-size: 2.5em;
  color: #1e3066;
  margin-bottom: 10px;
  /* font-style: italic; */
  
}

.imgpeixe {
  width: 80%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.baleia{
  width: 130%;
}

.textinho {
  font-size: 2em;
  color: #1e3066;
  /* font-weight: bold; */
  font-style: italic;
}

.textinho, .textopeixe{
  text-align: center;
}