.headerContainer {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;


    .hidro {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .article-header-container {
        margin: 8rem 0 100px 5rem;
        color: whitesmoke;
        display: flex;
        flex-flow: column nowrap;
        row-gap: 2rem;

        /* display: grid;
        grid-template-columns: 5% 40%;
        grid-template-rows: 40% 50%;
        color: whitesmoke;
        gap: 1rem; */


    }

    #phytobloom {
        font-size: 100px;
        /* grid-column: 2/3;
            grid-row: 1/2; */
        width: 1085px;
        /* align-self: end; */
        color: #fff;
    }

    #textophytobloom {
        grid-column: 2/3;
        grid-row: 2/3;
        width: 70rem;
        font-size: 2.6rem;
        color: #fff;
    }

    .fundoInvinsivel {

        background-color: rgba(240, 248, 255, 0);
        color: whitesmoke;
        margin: 0;
        font-size: 2rem;
        height: auto;
        width: 300px;
        padding: 20px;
        border-radius: 40px;
        border: 2px solid whitesmoke;
        margin-top: 20px;
        position: static;

        /* margin-left: 83px; */
        /* margin-top: 600px; */
        /* background-color: rgba(240, 248, 255, 0);
            color: whitesmoke;

            margin: 0;

            height: 50px;
            width: 150px;
            border-radius: 15px;
            border: 2px solid whitesmoke */
    }

    .fundoInvinsivel a {

        text-decoration: none;
        color: inherit;

        /* Fazendo o A ocupar toda a área do button
            E alinha o texto dentro do A
        */
        display: inline-flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 1920px) and (min-width: 1366px) {
    .headerContainer {
        height: 55rem;
    }
}

@media screen and (max-width: 1366px) {

    .headerContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 50rem;

        .hidro {
            position: absolute;
            /* width: 100%; */
            height: 100%;
            z-index: -1;


        }

        .article-header-container {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 2rem;


            #phytobloom {
                width: 65%;
            }

            #textophytobloom {
                width: 50%;
            }

            button.fundoInvinsivel {
                margin: 0;
                height: auto;
                width: 220px;
                border-radius: 50px;
                font-size: 1.5rem;
            }
        }

        #phytobloom {
            width: 160%;
        }

        #textophytobloom {
            grid-column: 2/3;
            grid-row: 2/3;
            width: 30rem;
            font-size: 2rem;
            color: #fff;

        }

        .fundoInvinsivel {
            margin-left: 50px;
            background-color: rgba(240, 248, 255, 0);
            color: whitesmoke;
            margin-top: 680px;
            height: 50px;
            width: 150px;
            border-radius: 15px;
            border: 2px solid whitesmoke
        }
    }

}


@media screen and (max-width: 1024px) {




    .headerContainer {
        position: relative;
        display: flex;
        flex-direction: column;


        .hidro {
            position: absolute;
            width: 100%;
            /* height: 60rem; */
            z-index: -1;


        }

        /* .article-header-container {
            margin: auto 80rem auto auto;
            display: grid;
            grid-template-columns: 5% 40%;
            grid-template-rows: 40% 50%;
            color: whitesmoke;
            gap: 1rem;


        } */

        .article-header-container {
            margin: auto;
            padding: 4rem 0 1rem 1.5rem;
        }

        #phytobloom {
            width: 160%;
        }

        .article-header-container #textophytobloom {
            grid-column: 2/3;
            grid-row: 2/3;
            width: 30rem;
            font-size: 2rem;
            color: #fff;

        }

        .fundoInvinsivel {
            margin-left: 50px;
            background-color: rgba(240, 248, 255, 0);
            color: whitesmoke;
            margin-top: 680px;
            height: 50px;
            width: 150px;
            border-radius: 15px;
            border: 2px solid whitesmoke
        }
    }

}


@media screen and (max-width: 500px) {
    .headerContainer .article-header-container {
        #textophytobloom {
            width: 80%;
            font-size: 1.4rem;
        }

        #phytobloom {
            width: 90%;
        }
    }

    .headerContainer {
        height: 40rem;
    }

}