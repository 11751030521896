.video {
  background-color: #2e7d32;
  /* height: 1250px; */

  p {
    text-align: center;
  }

  h1 {
    color: whitesmoke;
    text-align: center;
    padding: 100px;
    align-items: center;

    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .video{
    h1{
      padding: 4rem 0;
    }
  }
}