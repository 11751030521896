@import '../styles/variables.scss';

.header{
    padding-top: 1rem;
    display: flex;
    justify-content: center;

    img{
        width: 15rem;
    }
    nav{
        ul{
            display: flex;
            align-items: center;
            gap: 3rem;

            a{
                color:$bgWhite;
                text-decoration: none;
                font-size: 2rem;
            }

        }
    }
}